<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      color="#EEEEEE"
      width="262"
    >
      <v-list-item>
        <v-list-item-avatar>
          <v-img src="https://randomuser.me/api/portraits/men/78.jpg"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ user[0] !== null ? user[0].person.firstName : '' }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-group
          v-for="item in items"
          :key="item.title"
          :prepend-icon="item.action"
          no-action
          color="success"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <template>
            <v-list-item
              v-for="subItem in item.items"
              :key="subItem.title"
              :to="subItem.to"
            >
              <v-list-item-content >
                <v-list-item-title>{{ subItem.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn block @click="salir" color="success">
            <v-icon>mdi-logout</v-icon>
            SALIR
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app color="#FFFFFF">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="mr-16"><img src="../../assets/logo2.png" alt="" height="45" width="200" class="mt-2"></v-toolbar-title>
      <v-spacer></v-spacer>
<!--      <v-list v-if="user" >{{ user[0].role.description + ':'}} </v-list>
      <v-list v-if="user" >{{ user[0].person.firstName}}</v-list>-->

      <v-toolbar-items  app class="hidden-sm-and-down">
        <v-btn href="/inicio"><v-icon> mdi-home </v-icon></v-btn >
        <v-btn v-if="this.user">{{ user[0] !== null ? user[0].role.description + ':' : '' }}  {{ user[0] !== null ? user[0].person.firstName : ''}}</v-btn>
      </v-toolbar-items>
      <v-menu class="hidden-md-and-up" >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="hidden-md-and-up"
             icon
             v-bind="attrs"
             v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
             <v-list-item-content>
               <v-list-item href="/inicio"><v-icon> mdi-home </v-icon>INICIO</v-list-item >
              <v-list-item v-if="this.user">{{ user[0] !== null ? user[0].role.description : '' + ': '}}{{ user[0] !== null ? user[0].person.firstName : ''}}</v-list-item>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main class="fondo">
       <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import {mapActions, mapState} from "vuex";
import { mdiShopping,mdiCreditCardSettings,mdiCog,mdiPointOfSale, mdiChartBar, mdiCameraSwitch } from '@mdi/js';

export default {

name: "Dashboard",
  data () {
    return {
      value: 'recent',
      svgPath: mdiShopping,mdiCreditCardSettings,mdiCog,mdiPointOfSale,mdiChartBar,mdiCameraSwitch,
      drawer: null,
      initiallyOpen: ['Entidades'],
      files: {
        build: 'mdi-office-building',
        account: 'mdi-account-group',
        home: 'mdi-home-variant',
      },
      items: [
        {
          action: mdiShopping,
          title: 'PRODUCTOS',
          items: [
            {
              title: 'Productos',
              to: '/producto'
            },
            {
              title: 'Kardex',
              to: '/kardex'
            },
          ]
        },
        {
          action: mdiCreditCardSettings,
          title: 'COMPRAS',
          items: [
            {
              title: 'Realizar Compras',
              to: '/facturas'
            },
            {
              title: 'Compras',
              to: '/compras'
            },
          ]
        },
        {
          action: mdiPointOfSale,
          title: 'VENTAS',
          items: [
            {
              title: 'Realizar Ventas',
              to: '/caja'
            },
            {
              title: 'Ventas',
              to: '/ventas'
            },
          ]
        },
        {
          action: mdiCameraSwitch,
          title: 'MERMAS',
          items: [
            {
              title: 'Realizar Mermas',
              to: '/realizar/mermas'
            },
            {
              title: 'Mermas',
              to: '/mermas'
            },
          ]
        },
        {
          action: mdiCog,
          title: 'CONFIGURACION',
          items: [
            {
              title: 'Sucursal',
              to: '/entidad'
            },
            {
              title: 'Area',
              to: '/area'
            },
            {
              title: 'Personal',
              to: '/personal'
            },
            {
              title: 'Accion Terapeutica',
              to: '/AccionTerapeutic'
            },
          ]
        },
        {
          action: mdiChartBar,
          title: 'REPORTES',
          items: [
            {
              title: 'Inventario',
              to: '/reportesInventario'
            },
            {
              title: 'Producto',
              to: '/reportesProducto'
            },
            {
              title: 'ventas',
              to: '/reportesVentas'
            },
            {
              title: 'compras',
              to: ''
            },
            {
              title: 'Productos',
              to: ''
            },
          ]
        },
      ],
    }
  },
  computed: {
    ...mapState('auth', ['user'])
  },
  mounted() {
    if (this.user[0].role.id === 3) {
      this.items = this.items.filter(value => value.title === "VENTAS")
    }
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout'
    }),

    async salir () {
        await this.logout();
        await this.$router.push("/").catch(()=>{});
    }
  }
}
</script>
<style scoped>
.active {
  color: #f4f4f4 !important;
}
.fondo {
  background: url(../../assets/svg/background.svg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}
</style>
